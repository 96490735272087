import React from 'react';
import './cta.css';

const CTA = () => {
    return (
        <div>
        </div>
    )
}

export default CTA
