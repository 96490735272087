import React from 'react';
import './App.css'
import {Blog, Footer, Features, Possibility, Header, WhatVitigame } from './containers';
import {CTA, Brand, Navbar } from './components';


const App = () => {
    return (
        <div className='App'>
            <div className='gradient__bg'>
                <Navbar />
                <Header />

            </div>
            <Brand />
            <WhatVitigame />
            <Features />
            <Possibility />
            <CTA />
            <Blog />
            <Footer />
           
        </div>
    )
}

export default App