import React, {useState} from 'react';
import {RiMenu3Line, RiCloseLine} from 'react-icons/ri';
import logo from '../../assets/logo_white_small.svg';

import './navbar.css';

const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false);

const Menu = () => {
    return (
        <>
            <p><a href='#thing'> Accueil </a></p>
            <p><a href='#thing'> Les bases taille</a></p>
            <p><a href='#thing'> Jouer a Vitigame</a></p>
            <p><a href='#thing'> Blog </a></p>
        </>
    )
}

    return (
        <div className='vitigame__navbar'>

            <div className = 'vitigame__navbar-links'>

                <div className = 'vitigame__navbar-links_logo'> 
                    <img src={logo} alt = 'logo' />  
                </div>

                <div className='vitigame__navbar-links_container'>
                    <Menu />
                </div>
            
            </div>

            <div className='vitigame__navbar-sign'>
                <p>Se connecter</p>
                <button type='button'>S'enregistrer</button>
            </div>

            <div className='vitigame__navbar-menu'>
                {toggleMenu
                  ? <RiCloseLine color= '#fff' size={27} onClick= {()=> setToggleMenu(false)} />
                  : <RiMenu3Line color= '#fff' size={27} onClick= {()=> setToggleMenu(true)} />
                }

                {toggleMenu && (
                    <div className='vitigame__navbar-menu_container scale-up-center'>
                        <div className='vitigame__navbar-menu_container-links'>
                            <Menu />
                            <div className='vitigame__navbar-menu_container-links-sign'>
                                <p>Se connecter</p>
                                <button type='button'>S'enregistrer</button>
                                </div>
                        </div>
                    </div>
                )
                 }

            </div>

            

        </div>
    )
}

export default Navbar
