import React from 'react'
import './features.css';



const Features = () => {
    return (
        <div>
        </div>
    )
}

export default Features
