import React from 'react'
import './whatVitigame.css';

const WhatVitigame = () => {
    return (
        <div>
        </div>
    )
}

export default WhatVitigame
