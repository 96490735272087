import React from 'react'
import './blog.css';
import Article from '../../components/article/Article';
import {blog01, blog02,blog03,blog04,blog05} from './imports';

const Blog = () => {
    return (
        <div className = 'vitigame__blog section__padding' id='blog'>

            <div className ='vitigame__blog-heading'>
                <h1 className='gradient__text'>
                    Retrouvez aussi nos articles sur la taille
                </h1>
            </div>

            <div className='vitigame__blog-container'>
                    <div className='vitigame__blog-container_groupA'>
                        <Article  imgUrl={blog01} date='01 Sept. 2022' title='Eviter le rhume. Mythe pour mieux tailler ?' />
                    </div>
                    <div className='vitigame__blog-container_groupB'>
                        <Article    imgUrl={blog02}  date='01 Sept. 2022' title='Eviter le rhume. Mythe pour mieux tailler ?' />
                        <Article   imgUrl={blog03}  date='01 Sept. 2022' title='Eviter le rhume. Mythe pour mieux tailler ?' />
                        <Article   imgUrl={blog04}  date='01 Sept. 2022' title='Eviter le rhume. Mythe pour mieux tailler ?' />
                        <Article   imgUrl={blog05}  date='01 Sept. 2022' title='Eviter le rhume. Mythe pour mieux tailler ?' />
                    </div>
            </div>

        </div>
    )
}

export default Blog
