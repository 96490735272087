import React from 'react';
import { felco } from './imports';
import './brand.css';

const Brand = () => (
  <div className="vitigame__brand section__padding">
    <div>
      <img src={felco}  />
    </div>
    <div>
      <img src={felco} />
    </div>
    <div>
      <img src={felco} />
    </div>
  </div>
);

export default Brand;