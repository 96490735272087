import React from 'react';
import './article.css';

const Article = ({imgUrl,date,title}) => {
    return (
        <div className='vitigame__blog-container_article'>
            <div className='vitigame__blog_container_article-image'>
                <img src = {imgUrl} alt='blog_img'/> 
            </div>
            <div className='vitigame__blog_container_article-content'>
                <div>
                    <p>{date}</p>
                    <h3>{title}</h3>
                </div>
                <p>Lire l'article</p>
            </div>


        </div>
    )
}

export default Article;
