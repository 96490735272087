import React from 'react';
import concept from '../../assets/concept3.png';
import './header.css';

const Header = () => (
  <div className="vitigame__header section__padding" id="home">
    <div className="vitigame__header-content">
      <h1 className="gradient__text">Apprends a tailler comme un pro avec vitigame</h1>
      <p>Yet bed any for travelling assistance indulgence unpleasing. Not thoughts all exercise blessing. Indulgence way everything joy alteration boisterous the attachment. Party we years to order allow asked of.</p>

      <div className="vitigame__header-content__input">
        <input type="email" placeholder="Adresse e-mail" />
        <button type="button">C'est parti</button>
      </div>

      </div>

    <div className="vitigame__header-image">
      <img src={concept} />
    </div>
  </div>
);

export default Header;