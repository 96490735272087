import React from 'react'
import './footer.css';
import vitigamelogo from '../../assets/logo_white.svg'

const Footer = () => {
    return (
        <div className='vitigame__footer section__padding'>
            <div className='vitigame__footer-heading'>
                <h1 className='gradient__text'>On recherche des partenaires</h1>
            </div>

            <div className='vitigame__footer-btn'>
                <p>Nous contacter</p>
            </div>

            <div className='vitigame__footer-links'>
                
                <div className='vitigame__footer-links_logo'>
                    <img src={vitigamelogo} alt='logo vitigame' />
                    <p>Mareuil/Cher, France. Tout droits reserves.</p>
                </div>

                <div className='vitigame__footer-links_div'>
                        <h4>Liens</h4>
                        <p>Twitter</p>
                        <p>Facebook</p>
                        <p>Contact</p>
                </div>

                <div className='vitigame__footer-links_div'>
                        <h4>Legal</h4>
                        <p>Termes et Conditions</p>
                        <p>Confidentialité </p>
                        <p>Contact</p>
                </div>

                <div className='vitigame__footer-links_div'>
                        <h4>On se voit ?</h4>
                        <p>rue des big boss,  41110 Mareuil/Cher</p>
                        <p>+33 784529645</p>
                        <p>fluxdeseve.com</p>
                </div>
                
            </div>
        </div>
    )
}

export default Footer
